import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LineIcon from "../../assets/pages/index/svgs/LineIcon.svg";
import QR from "../../assets/pages/login/QR.png";

interface AddOurLineSectionProps {
  className?: string;
  text?: string;
  lang?: string;
  th?: boolean;
}

function AddLineDialog({ isOpen, closeModal }: any) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#000000c6]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 pb-10 my-8 overflow-hidden text-left align-middle justify-content transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="flex flex-col items-center">
                <img className="w-[220px] h-[220px] mb-6 m-auto" src={QR} />
                <p className="text-2xl text-center">
                  Scan the QR code to <br />
                  register course
                </p>
                <p className="text-center mb-6">OR</p>
                <a href="line://ti/p/@taratera" target="_blank" className="bg-pineGreen px-6 py-4 text-white rounded-4 hover:opacity-90">
                  Chat with Taratera
                </a>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

const AddOurLineButton = ({ buttonText, onClick, th }: any) => {
  return (
    <>
      <button
        className="mt-10 md:mt-0 h-16 md:h-20 w-[200px] md:w-[280px] flex justify-center items-center border-[1px] border-white hover:bg-pineGreen transition-all hover:border-pineGreen"
        onClick={onClick}
      >
        <img src={LineIcon} className="mr-2 md:mr-4" />
        <span className={`text-[16px] text-white  ${th && `font-family: Sarabun`}`}>{buttonText ? buttonText : "ADD OUR LINE"}</span>
      </button>
    </>
  );
};

const AddOurLineSection: React.FC<AddOurLineSectionProps> = ({ className, text, lang, th }) => {
  let [isOpen, setIsOpen] = useState(false);

  return (
    <div className={"w-screen h-auto bg-darkNight md:h-[336px] px-6 py-6 md:px-0 lg:p-0 sm:py-10"} id="addourline">
      <div className="h-full lg:mx-[64px] xl:mx-[112px] 2xl:mx-auto md:mx-6 max-w-screen-xl grid grid-cols-12 gap-x-0 sm:gap-x-10 items-center">
        <div className="col-span-12 md:col-span-9 px-0 sm:py-10 lg:py-[64] xl:py-[120px]">
          <p
            className={`w-full text-left justify-center font-medium md:font-medium text-white leading-[48px] text-[32px] lg:text-[40px] mb-0 ${
              th && `font-family: Sarabun`
            }`}
          >
            {text}
          </p>
        </div>
        <div className="col-span-12 w-full md:col-span-3 px-0 lg:py-[64] xl:py-[120px] flex md:justify-end md:items-center">
          <AddOurLineButton buttonText={lang} onClick={() => setIsOpen(true)} th={th} />
        </div>
      </div>
      <AddLineDialog isOpen={isOpen} setIsOpen={setIsOpen} closeModal={() => setIsOpen(false)} />
    </div>
  );
};

export default AddOurLineSection;
