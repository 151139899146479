import React, { useState, useRef, useLayoutEffect } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Page from '../components/Common/Page'
import IndexLayout from '../layouts'
import BannerSection from '../components/pages/ServicesPage/BannerSection'
import OurServicesSection from '../components/pages/ServicesPage/OurServicesSection'
import OurProductsSection from '../components/pages/ServicesPage/OurProductsSection'
import AddOurLineSection from '../components/Common/AddOurLineModal'
import { NavigationDots } from '../components/pages/ServicesPage/NavigationDots'

const ServicesPage = () => {
  const [check, setCheck] = useState(false)
  const [show, doShow] = React.useState({
    navbar: false,
    itemOne: false,
    itemTwo: false,
    itemThree: false
  })
  const [percentShown, setPercentShow] = useState({
    navbar: 0,
    itemOne: 0,
    itemTwo: 0,
    itemThree: 0,
    imageOne: 0,
    imageTwo: 0,
    imageThree: 0,
    imageFour: 0
  })
  const refNavbar = useRef(null)
  const refOne = useRef(null)
  const refTwo = useRef(null)
  const refThree = useRef(null)

  const [activeStep, setActiveStep] = useState('#banner')
  const handleSelectDot = (id: string) => {
    scrollTo(id)
  }

  useLayoutEffect(() => {
    const topPos = (element: any) => element.getBoundingClientRect().top + window.scrollY
    const bottomPos = (element: any) => element.getBoundingClientRect().bottom + window.scrollY

    const section1Bottom = bottomPos(refOne.current)
    const section2Bottom = bottomPos(refTwo.current)
    const section3Top = topPos(refThree.current)
    const section3Bottom = bottomPos(refThree.current)
    const section3Width = window.innerWidth * 0.5
    const section3Height = (section3Bottom - section3Top) / 4
    const section3BreakPoint = section3Top + section3Height
    const section3BreakPointIn = section3Top - section3Height

    const onScroll = () => {
      if (window) {
        const scrollPos = window.scrollY + window.innerHeight
        if (window.scrollY > 16) {
          doShow(state => ({ ...state, navbar: true }))
        } else {
          doShow(state => ({ ...state, navbar: false }))
        }

        if (scrollPos - 100 < section1Bottom) {
          setActiveStep('#banner')
          doShow(state => ({ ...state, itemOne: true, itemTwo: false, itemThree: false }))
        } else if (scrollPos > section1Bottom && scrollPos < section2Bottom) {
          setActiveStep('#ourservice')
          doShow(state => ({ ...state, itemOne: true, itemTwo: true, itemThree: false }))
          setPercentShow(state => ({ ...state, imageOne: 0, imageTwo: 0, imageThree: 0, imageFour: 0 }))
        } else if (scrollPos > section2Bottom && scrollPos < section3Bottom) {
          setActiveStep('#ourproduct')
          doShow(state => ({ ...state, itemOne: true, itemTwo: true, itemThree: true }))
          const section3Position = (scrollPos - section3BreakPointIn) / section3Height
          const sectionNumber = Math.floor(section3Position)
          const section3Increase = section3Position % 1
          const section3Decrease = 1 - section3Increase
          if (sectionNumber === 1) {
            setPercentShow(state => ({ ...state, itemThree: 0, imageOne: section3Increase }))
          } else if (sectionNumber === 2) {
            setPercentShow(state => ({
              ...state,
              itemThree: ((scrollPos - section3BreakPoint) / section3Height) * section3Width,
              imageOne: section3Decrease,
              imageTwo: section3Increase
            }))
          } else if (sectionNumber === 3) {
            setPercentShow(state => ({
              ...state,
              itemThree: ((scrollPos - section3BreakPoint) / section3Height) * section3Width,
              imageTwo: section3Decrease,
              imageThree: section3Increase
            }))
          } else if (sectionNumber === 4) {
            setPercentShow(state => ({
              ...state,
              itemThree: ((scrollPos - section3BreakPoint) / section3Height) * section3Width,
              imageThree: section3Decrease,
              imageFour: section3Increase
            }))
          }
        } else {
          const section3Position = (scrollPos - section3BreakPointIn) / section3Height
          const section3Increase = section3Position % 1
          const section3Decrease = 1 - section3Increase
          setPercentShow(state => ({ ...state, itemThree: section3Width * 3 }))
          setActiveStep('#addourline')
          setPercentShow(state => ({
            ...state,
            itemThree: section3Width * 3,
            imageFour: section3Decrease,
            itemTwo: 0
          }))
          doShow(state => ({ ...state, itemOne: true, itemTwo: true, itemThree: true }))
        }
      }
    }

    const changeCheck = () => {
      setCheck(!check)
    }

    if (window) {
      window.addEventListener('scroll', onScroll)
      window.addEventListener('resize', changeCheck)
      setActiveStep('#banner')
      doShow(state => ({ ...state, itemOne: true }))
    }
    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', changeCheck)
    }
  }, [refTwo])

  return (
    <IndexLayout ref={refNavbar} animate={show.navbar}>
      <Page>
        <BannerSection ref={refOne} animate={show.itemOne} />
        <OurServicesSection ref={refTwo} animate={show.itemTwo} />
        <OurProductsSection ref={refThree} animate={show.itemThree} percent={percentShown} />
        <AddOurLineSection text={'We would love to talk to you'} />
        <NavigationDots activeStep={activeStep} selectDot={handleSelectDot} />
      </Page>
    </IndexLayout>
  )
}

export default ServicesPage
