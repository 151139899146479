import React, { forwardRef } from "react";
import SeedbankBg from "../../../assets/pages/services/SeedbankBg.png";
// import CultivationBg from '../../../assets/pages/services/CultivationBg.png'
import ExtractionBg from "../../../assets/pages/services/ExtractionBg.png";
//@ts-ignore
import ServiceBg from "../../../assets/videos/ServiceBg.mp4";

const BoxTitle = ({ children }: any) => (
  <h1 className={`font-medium capitalize text-darkNight text-[24px] lg:text-[32px] sm:leading-[48px] z-10 mb-0 leading-8 pr-4 sm:pr-0`}>
    {children}
  </h1>
);

const BoxSubTitle = ({ children }: any) => (
  <h2 className={`font-light text-xl lg:text-[24px] text-darkNight sm:leading-8 mt-0 z-10 mb-0 leading-6`}>{children}</h2>
);

interface OurServicesProps {
  className?: string;
  animate?: boolean;
}

const OurServicesSection = forwardRef(({ className, animate }: OurServicesProps, ref: any) => (
  <div id="ourservice" ref={ref}>
    <div
      className={`relative w-screen h-screen bg-center bg-cover bg-no-repeat flex justify-center`}
      style={{ backgroundImage: `url(${SeedbankBg})` }}
      id="seedbank"
    >
      <div className="absolute top-0 left-0 bg-gradient-to-b from-[#636363e6] h-1/2 w-screen bg-local overflow-x-hidden z-[0]" />
      <div className="w-full h-full lg:!mx-16 xl:!mx-28 max-w-screen-xl mx-auto z-10">
        <div className="flex flex-col justify-between pt-6 px-6 mb-0 h-full sm:px-6 md:px-8 lg:px-0 md:pt-10 sm:mb-20 xl:mb-0 xl:pt-[80px]">
          <h1
            className={`font-medium capitalize text-white text-[32px] lg:text-[64px] leading-[48px] z-10 transition-all ease-out duration-1500 translate-y-10 ${
              animate ? `opacity-100 translate-y-0` : `opacity-0`
            }`}
          >
            Our services
          </h1>
          <div className="flex flex-col gap-y-2 md:w-3/4 max-w-[860px] max-h-[464px] bg-[#ffffff85] backdrop-blur-lg p-10">
            <BoxTitle>Seedbank</BoxTitle>
            <BoxSubTitle>
              Our breeders, in partnership with seedbanks across the US and EU, can help you fine-tune the crossing of multiple strains to yield the
              most preferable characteristics and source feminized seeds
            </BoxSubTitle>
          </div>
        </div>
      </div>
    </div>
    <div className={`relative w-screen h-screen bg-center bg-cover bg-no-repeat flex justify-center`} id="cultivation">
      <video
        className="absolute object-cover left-0 h-full top-0 md:top-0 sm:h-full w-screen bg-local overflow-x-hidden z-[0] bg-center bg-cover bg-no-repeat"
        autoPlay
        muted
        loop
        id="myVideo"
        playsInline
      >
        <source src={ServiceBg} type="video/mp4" />
      </video>
      <div className="w-full lg:!mx-16 xl:!mx-28 h-full xl:max-w-screen-xl mx-auto z-10">
        <div className="flex flex-col justify-between pt-6 px-6 mb-0 h-full sm:px-6 md:px-8 lg:px-0 md:pt-10 sm:mb-20 xl:mb-0 xl:pt-[80px]">
          <br />
          <div className="flex flex-col gap-y-2 md:w-3/4 max-w-[860px] max-h-[464px] bg-[#ffffff85] backdrop-blur p-10">
            <BoxTitle>Cultivation Consulting & Construction</BoxTitle>
            <BoxSubTitle>
              Our growers and architects can provide a complete design of cultivation facility, from complex hydroponic pipework to lighting design,
              that is streamlined for production
            </BoxSubTitle>
          </div>
        </div>
      </div>
    </div>
    <div
      className={`relative w-screen h-screen bg-center bg-cover bg-no-repeat flex justify-center`}
      style={{ backgroundImage: `url(${ExtractionBg})` }}
      id="extraction"
    >
      <div className="w-full lg:!mx-16 xl:!mx-28 h-full xl:max-w-screen-xl mx-auto z-10">
        <div className="flex flex-col justify-between pt-6 px-6 mb-0 h-full sm:px-6 md:px-8 md:pt-10 lg:px-0 sm:mb-20 xl:mb-0 xl:pt-[80px]">
          <br />
          <div className="flex flex-col gap-y-2 md:w-3/4 max-w-[860px] max-h-[464px] bg-[#ffffff85] backdrop-blur p-10">
            <BoxTitle>Extraction Lab</BoxTitle>
            <BoxSubTitle>
              Our extractors can provide a range of CBD extracts and white labeling services for a wide variety of products, tailored to your needs
            </BoxSubTitle>
          </div>
        </div>
      </div>
    </div>
  </div>
));

export default OurServicesSection;
