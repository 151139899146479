import React from "react";
// import tw, { styled } from 'twin.macro'

const Container = ({ children }: any) => <div className={`fixed top-1/2vh h-36 z-10 !right-3 md:right-14`}>{children}</div>;

const Circle = ({ children }: any) => <circle className={`transition-all stroke-2 cursor-pointer`}>{children}</circle>;

const Path = ({ children }: any) => <path className={`cursor-pointer`}>{children}</path>;

const Svg = ({ children }: any) => <svg className={`w-8 h-36 sm:w-9 sm:h-44`}>{children}</svg>;

export const NavigationDots = ({ activeStep, selectDot }: any) => {
  return (
    <div className="fixed top-[calc(50vh-88px)] h-36 z-10 !right-3 md:right-14">
      <svg className="w-8 h-36 sm:w-9 sm:h-44" viewBox="0 0 36 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          className="transition-all stroke-2 cursor-pointer"
          cx="18"
          cy="14"
          r="9"
          stroke={activeStep === "#banner" ? "#86A791" : "#0000000"}
          stroke-width="2"
        />
        <g filter="url(#filter0_d_715_4479)">
          <path
            className="cursor-pointer"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 14C13 11.24 15.24 9 18 9C20.76 9 23 11.24 23 14C23 16.76 20.76 19 18 19C15.24 19 13 16.76 13 14Z"
            fill="#86A791"
            onClick={() => selectDot("#banner")}
          />
        </g>
        <circle
          className="transition-all stroke-2 cursor-pointer"
          cx="18"
          cy="54"
          r="9"
          stroke={activeStep === "#ourservice" ? "#86A791" : "#0000000"}
          stroke-width="2"
        />
        <g filter="url(#filter1_d_715_4479)">
          <path
            className="cursor-pointer"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 54C13 51.24 15.24 49 18 49C20.76 49 23 51.24 23 54C23 56.76 20.76 59 18 59C15.24 59 13 56.76 13 54Z"
            fill="#86A791"
            onClick={() => selectDot("#ourservice")}
          />
        </g>
        <circle
          className="transition-all stroke-2 cursor-pointer"
          cx="18"
          cy="94"
          r="9"
          stroke={activeStep === "#ourproduct" ? "#86A791" : "#0000000"}
          stroke-width="2"
        />
        <g filter="url(#filter2_d_715_4479)">
          <path
            className="cursor-pointer"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 94C13 91.24 15.24 89 18 89C20.76 89 23 91.24 23 94C23 96.76 20.76 99 18 99C15.24 99 13 96.76 13 94Z"
            fill="#86A791"
            onClick={() => selectDot("#ourproduct")}
          />
        </g>
        <circle
          className="transition-all stroke-2 cursor-pointer"
          cx="18"
          cy="134"
          r="9"
          stroke={activeStep === "#addourline" ? "#86A791" : "#0000000"}
          stroke-width="2"
        />
        <g filter="url(#filter3_d_715_4479)">
          <path
            className="cursor-pointer"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 134C13 131.24 15.24 129 18 129C20.76 129 23 131.24 23 134C23 136.76 20.76 139 18 139C15.24 139 13 136.76 13 134Z"
            fill="#86A791"
            onClick={() => selectDot("#addourline")}
          />
        </g>
        <defs>
          <filter id="filter0_d_715_4479" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_715_4479" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_715_4479" result="shape" />
          </filter>
          <filter id="filter1_d_715_4479" x="0" y="40" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_715_4479" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_715_4479" result="shape" />
          </filter>
          <filter id="filter2_d_715_4479" x="0" y="80" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_715_4479" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_715_4479" result="shape" />
          </filter>
          <filter id="filter3_d_715_4479" x="0" y="120" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_715_4479" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_715_4479" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
