import React, { forwardRef } from "react";
import CBDDistillate from "../../../assets/pages/services/CBDDistillate.png";
import CBDIsolate from "../../../assets/pages/services/CBDIsolate.png";
import WaterSoluble from "../../../assets/pages/services/WaterSoluble.png";
import WhiteLabel from "../../../assets/pages/services/WhiteLabel.png";

const WrapText = ({ children }: any) => <div className={`flex flex-col w-full justify-between items-start`}>{children}</div>;

const Subtitle = ({ children }: any) => <p className={`text-white text-2xl sm:text-[32px] mb-4 font-medium`}>{children}</p>;

const Desc = ({ children }: any) => <p className={`text-white text-lg sm:text-[20px] font-extralight mb-5 md:mb-6`}>{children}</p>;

const Ul = ({ children }: any) => <ul className={`ml-[18px] text-white text-lg sm:text-[20px] font-extralight`}>{children}</ul>;

const Li = ({ children }: any) => <li className={`mb-5 md:mb-6`}>{children}</li>;

const Disclaimer = ({ children }: any) => <p className={`text-[12px] text-white font-light mb-0`}>{children}</p>;

interface OurProductsSectionProps {
  className?: string;
  animate?: boolean;
  percent: {
    [key: string]: number;
    itemTwo: number;
    imageOne: number;
    imageTwo: number;
    imageThree: number;
    imageFour: number;
  };
}

const OfferingsData: { key: string; title: any; link: string; bgImage: string }[] = [
  {
    key: "imageOne",
    title: (
      <WrapText>
        <Subtitle>CBD Distillate</Subtitle>
        <Desc>Distillates are high potency CBD extracts, with full-spectrum cannabinoids, that are purified through a process of distillation</Desc>
        <Ul>
          <Li>Characteristics: Amber oil with limited cannabis taste and odor</Li>
          <Li>Potency: 80-90%*</Li>
        </Ul>
        <Disclaimer>*Though, CBD distillate will be sold in diluted form, in compliance with Thailand’s regulations</Disclaimer>
      </WrapText>
    ),
    link: "/",
    bgImage: CBDDistillate,
  },
  {
    key: "imageTwo",
    title: (
      <WrapText>
        <Subtitle>CBD Isolate</Subtitle>
        <Desc>Isolates are pure CBD extracts, with no other cannabinoids, that are isolated through a process of crystallization</Desc>
        <Ul>
          <Li>Characteristics: White crystalline solid with no cannabis taste and odor</Li>
          <Li>{"Potency: >98%"}</Li>
        </Ul>
      </WrapText>
    ),
    link: "/",
    bgImage: CBDIsolate,
  },
  {
    key: "imageThree",
    title: (
      <WrapText>
        <Subtitle>Water Soluble CBD</Subtitle>
        <Desc>Water-compatible CBD extracts, with higher bioavailability, that are produced via a nano-emulsification technology</Desc>
        <Ul>
          <Li>Characteristics: Clear liquid with limited taste and odor</Li>
          <Li>{"Potency: 5-20%"}</Li>
        </Ul>
      </WrapText>
    ),
    link: "/",
    bgImage: WaterSoluble,
  },
  {
    key: "imageFour",
    title: (
      <WrapText>
        <Subtitle>White Label CBD Products</Subtitle>
        <Desc>A range of ready-for-market CBD-based consumer products that are offered with full processing and packaging</Desc>
      </WrapText>
    ),
    link: "/",
    bgImage: WhiteLabel,
  },
];

const OurProductsSection = forwardRef(({ className, animate, percent }: OurProductsSectionProps, ref: any) => (
  <>
    <div className={"relative w-screen"} ref={ref} id="ourproduct">
      <div className="relative h-full md:h-[400vh]">
        <h1
          className={`absolute left-[calc(50% + 40px)] top-14 font-medium capitalize text-darkNight text-[32px] lg:text-[64px] transition-all ease-out duration-1500 translate-y-10 mb-4 ${
            animate ? `opacity-100 translate-y-0` : `opacity-0`
          }`}
        >
          Our Products
        </h1>
        <div
          className={`hidden sm:flex sticky top-[0px] h-screen w-[fit-content] will-change[transform] transform-style[preserve-3d] bg-darkNight`}
          style={{
            transform: `translate3d(-${percent["itemThree"]}px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
          }}
        >
          {OfferingsData.map((data) => (
            <div
              className="h-[350px] w-full sm:h-full sm:w-[50vw] bg-local overflow-x-hidden z-[0] bg-center bg-cover bg-no-repeat"
              key={data.bgImage}
              style={{ backgroundImage: `url(${data.bgImage})`, opacity: `${percent[data.key]}` }}
            />
          ))}
        </div>
        <div className="hidden sm:flex sm:flex-col absolute w-[50vw] top-0 right-0 h-[fit-content] z-10">
          {OfferingsData.map((data) => (
            <div className="hidden sm:flex h-auto md:h-screen" key={data.key}>
              <div className="flex w-full items-end sm:items-center p-6 sm:p-10 sm:pr-[140px] bg-pineGreen border-b-[1px] border-white">
                {data.title}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="relative sm:hidden">
        {OfferingsData.map((data) => (
          <div className="flex flex-col sm:hidden h-auto">
            <div
              className="h-[350px] w-full sm:h-full sm:w-[50vw] bg-local overflow-x-hidden z-[0] bg-center bg-cover bg-no-repeat"
              style={{ backgroundImage: `url(${data.bgImage})` }}
            />
            <div className="flex w-full items-end sm:items-center p-6 sm:p-10 sm:pr-[140px] bg-pineGreen border-b-[1px] border-white">
              {data.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  </>
));

export default OurProductsSection;
