import React, { forwardRef } from "react";
//@ts-ignore
import BgVideo from "../../../assets/videos/ServiceBannerVideoBg.mp4";

interface BannerSectionProps {
  className?: string;
  animate?: boolean;
}

const BannerSection = forwardRef(({ className, animate }: BannerSectionProps, ref: any) => (
  <>
    <div className={"relative w-screen h-screen md:h-screen bg-center bg-no-repeat bg-[#0a090b] flex justify-center"} ref={ref} id="banner">
      <video
        className="absolute object-cover right-0 h-full top-0 md:top-0 sm:h-full bg-local overflow-x-hidden z-[0] bg-center bg-no-repeat"
        autoPlay
        muted
        loop
        id="myVideo"
        playsInline
      >
        <source src={BgVideo} type="video/mp4" />
      </video>
      <div className="absolute bottom-0 left-0  bg-gradient-to-t from-[#636363e6] opacity-80 w-full h-1/2 z-10" />
      <div className="w-full h-full lg:!mx-16 xl:!mx-28 xl:max-w-screen-xl mx-auto z-10">
        <div className="flex justify-start items-center sm:items-end pt-6 px-6 mb-0 pb-10 h-full sm:px-6 md:px-8 md:pt-24 sm:mb-20 xl:mb-0 xl:pt-36 lg:px-0 xl:pb-[150px] 2xl:pl-0">
          <h1
            className={`font-medium mb-0 sm:mb-0 text-white text-[32px] lg:text-[56px] xl:text-[80px] leading-[48px] md:leading-[88px] z-10 transition-all ease-out duration-1500 translate-y-10 ${
              animate ? `opacity-100 translate-y-0` : `opacity-0`
            }`}
          >
            From dreams to plans and plans to reality, Taratera pride ourselves in one-stop service for cannabis
          </h1>
        </div>
      </div>
    </div>
  </>
));

export default BannerSection;
